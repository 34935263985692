<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Izvēles lauki"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-button variant="success" class="float-right" :to="{ name: 'RoleCreate' }">Pievienot</b-button>

          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-modal id="modal-sm" size="sm" title="Dzēst lomu" ok-variant="danger" @ok="deleteItem" ok-title="Jā" cancel-title="Nē" bvModalEvent.trigger = 'delete()'>Vai jūs tiešām vēlaties dzēst šo lomu?</b-modal>

          <b-alert
              :show="dismissCountDown"
              v-if="deleted == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Loma tika veiksmīgi izdzēsta!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="deleted == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p>{{ errorMessage }}</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

            <b-table striped hover responsive="true" fixed :items="data" :fields="fields" v-if="loaded">

              <template #cell(actions)="row">
                <b-button size="sm" variant="warning" @click="$router.push({ name: 'RoleView', params: {id: row.item.id} })" class="mr-1">
                  Labot/Skatīt
                </b-button>
                <b-button size="sm" variant="danger" v-b-modal.modal-sm @click="selectedId = row.item.id">
                  Dzēst
                </b-button>
              </template>
            </b-table>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      loaded: false,
      data: [],
      fields : [
        { key: 'name', label: 'Nosaukums' },
        { key: 'actions', label: 'Darbības' }
      ],
      selectedId: null,
      deleted: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      errorMessage: null,
    }
  },
  methods: {
    getItems() {
      axios.get('/roles').then(response => {
        console.log(response.data);
        this.data = response.data.data;
      })
    },
    deleteItem() {
      if(this.selectedId != null) {
        axios.delete('/roles/'+this.selectedId).then(response => {
          this.deleted = 'success'

          this.getItems()
        }).catch(e => {
          this.deleted = 'error'
          this.errorMessage = e.response.data.message
        })

        this.dismissCountDown = 5;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  },
  async mounted() {
    this.getItems()
    this.loaded = true
  },
}
</script>
